<template>
  <div class="row content-wrapper">
    <LoadingSpinner v-if="isLoading" />
    <template v-else>
      <div
        class="col-md-4 content-list-wrapper"
        v-for="spot in spots"
        :key="spot.id"
      >
        <div class="content-item-wrapper">
          <!-- <router-link :to="`/destination/${spot.id}?page=${page}`">
            <img
              :src="spot.thumbnail"
              :alt="spot.name"
              class="list-thumbnail"
            />
            <h4 class="spot-title">{{ spot.name }}</h4>
            <p class="spot-desc">{{ spot.summary }}</p>
          </router-link> -->
          <a :href="`/destination/${spot.id}?page=${page}`">
            <img
              :src="spot.thumbnail"
              :alt="spot.name"
              class="list-thumbnail"
            />
            <h4 class="spot-title">{{ spot.name }}</h4>
            <p class="spot-desc">{{ spot.summary }}</p>
          </a>
        </div>
      </div>
      <div class="row pagination-container">
        <div class="col-md-12">
          <paginate
            v-model="page"
            :page-count="spotsLastPage"
            :click-handler="fetchMoreData"
            :container-class="'pagination justify-content-center'"
            :page-class="'page-item'"
            :page-range="7"
            :prev-text="''"
            :next-text="''"
          >
          </paginate>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';
import { mapState, mapActions } from 'vuex';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

export default {
  components: {
    Paginate,
    LoadingSpinner,
  },
  data() {
    return {
      totalPage: null,
      totalRows: 0,
      page: 1,
      isLoading: false,
    };
  },
  // watch: {
  //   page() {
  //     this.reloadPageNum();
  //   },
  // },
  computed: {
    ...mapState(['spots', 'spotsLastPage']),
  },
  created() {
    if (!isNaN(Number(this.$route.query.page))) {
      this.page = Number(this.$route.query.page);
    } else {
      this.page = 1;
    }
    this.fetchData(this.page);
  },
  mounted() {
    // this.$nextTick(function () {
    //   for (let i = 0; i < document.querySelectorAll('.page-item').length; i++) {
    //     const element = document.querySelectorAll('.page-item')[i];
    //     element.classList.remove('active');
    //   }
    //   document
    //     .querySelectorAll('.page-item')
    //     [this.page - 1].classList.add('active');
    // });
  },
  methods: {
    reloadPageNum() {
      for (let i = 0; i < document.querySelectorAll('.page-item').length; i++) {
        const element = document.querySelectorAll('.page-item')[i];
        element.classList.remove('active');
      }
      document
        .querySelectorAll('.page-item')
        [this.page - 1].classList.add('active');
    },
    ...mapActions(['FETCH_SPOTS']),
    fetchData(page) {
      this.isLoading = true;
      this.FETCH_SPOTS(page);
      this.isLoading = false;
    },
    fetchMoreData(pageNum) {
      this.page = pageNum;
      this.fetchData(pageNum);
      this.$router.replace({ query: { ...this.$route.query, page: pageNum } });
      window.scrollTo(0, 0);
      // for (let i = 0; i < document.querySelectorAll('.page-item').length; i++) {
      //   const element = document.querySelectorAll('.page-item')[i];
      //   element.classList.remove('active');
      // }
      // document
      //   .querySelectorAll('.page-item')
      //   [this.page - 1].classList.add('active');
    },
  },
};
</script>

<style scoped>
.spot-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
.spot-desc {
  margin-bottom: 0;
  font-size: 16px;
  color: #333;
  word-break: keep-all;
}
.content-item-wrapper {
  margin-bottom: 50px;
}
.list-thumbnail {
  border-radius: 5px;
}
</style>
