<template>
  <div>
    <ListComponent />
  </div>
</template>

<script>
import ListComponent from '@/components/spots/ListComponent.vue';

export default {
  components: {
    ListComponent,
  },
};
</script>

<style></style>
